<template>
  <div>
    <!-- product side bar form  -->
    <product-list-add-new
      :is-add-new-document-type-sidebar-active.sync="
        isAddNewDocumentTypeSidebarActive
      "
      :id="id"
      :data="documenttypeData"
      :product-options="productOptions"
      @refetch-data="refetchData"
      v-if="ability.can('create', 'documenttypes') || true"
    >
    </product-list-add-new>

    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <div class="d-flex flex-wrap flex-md-nowrap align-items-center">
          <h3 class="font-weight-bolder text-nowrap mb-0">Product</h3>
          <div class="form-col-select ml-2">
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block"
            />
            <feather-icon size="18" icon="ChevronDownIcon" />
          </div>
          <div class="search-primary w-100">
            <feather-icon size="16" icon="SearchIcon" />
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              placeholder="Search ..."
            />
          </div>
          <b-button
            variant="primary"
            class="px-3 mobile-w100"
            @click="handleCreate"
          >
            <span class="text-nowrap">Add Product</span>
          </b-button>
        </div>
      </div>

      <b-table
        ref="refProductTable"
        class="position-relative"
        :items="fetchProduct"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Actions -->
        <template #cell(product_type_id)="data">
          <p>
            {{
              data.item.product_type_id
                ? productOptions.filter(
                    (ele) => ele.id === data.item.product_type_id
                  )[0].type
                : "N/A"
            }}
          </p>
        </template>

        <!-- Column: Actions -->
        <template #cell(is_active)="data">
          <div
            v-if="data.item.is_active == 1"
            size="18"
            class="mr-50 text-success"
          >
            Active
          </div>
          <div
            v-if="data.item.is_active == 0"
            size="18"
            class="mr-50 text-secondary"
          >
            Inactive
          </div>
        </template>

        <template #cell(action)="data">
          <b-dropdown
            class="action-trigger-btn"
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="EyeIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              v-if="ability.can('update', 'documenttypes') || true"
              @click="handleEdit(data.item)"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>
            <b-dropdown-item @click="confirmDelete(data.item.id)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>

      <!-- Pagination  -->
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalProduct"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import useProductList from "./useProductList";
import ProductStoreModule from "../ProductStoreModule";
import ProductListAddNew from "./ProductListAddNew.vue";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    ProductListAddNew,
  },
  data() {
    return {
      id: 0,
      documenttypeData: {},
    };
  },
  methods: {
    handleCreate() {
      this.id = 0;
      this.resetDocumentTypeData();
      this.isAddNewDocumentTypeSidebarActive = true;
    },
    handleEdit(item) {
      this.id = item.id;
      this.documenttypeData = { ...item };
      this.isAddNewDocumentTypeSidebarActive = true;
    },
    resetDocumentTypeData() {
      this.documenttypeData = {
        id: 0,
        type: "",
      };
    },
    confirmDelete(id) {
      this.loading = true;
      this.$swal({
        title: "Are you sure?",
        text: "Your action is final and you will not be able to retrieve the document type.",
        showCancelButton: true,
        confirmButtonText: "Delete",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      })
        .then((result) => {
          if (result.value) {
            this.$store
              .dispatch("product/deleteProduct", { id: id })
              .then((response) => {
                this.$swal({
                  text: response.data.message,
                  icon: "success",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  showClass: {
                    popup: "animate__animated animate__bounceIn",
                  },
                  buttonsStyling: false,
                });
                this.refetchData();
              })
              .catch((error) => {
                this.$swal({
                  text: JSON.stringify(error.response.data.message),
                  icon: "error",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  showClass: {
                    popup: "animate__animated animate__bounceIn",
                  },
                  buttonsStyling: false,
                });
              });
          }
        })
        .finally(() => (this.loading = false));
    },
  },
  setup() {
    const PRODUCT_TYPE_STORE_MODULE_NAME = "product";

    if (!store.hasModule(PRODUCT_TYPE_STORE_MODULE_NAME))
      store.registerModule(PRODUCT_TYPE_STORE_MODULE_NAME, ProductStoreModule);

    onUnmounted(() => {
      if (store.hasModule(PRODUCT_TYPE_STORE_MODULE_NAME))
        store.unregisterModule(PRODUCT_TYPE_STORE_MODULE_NAME);
    });

    const isAddNewDocumentTypeSidebarActive = ref(false);

    const {
      fetchProduct,
      tableColumns,
      perPage,
      currentPage,
      totalProduct,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refProductTable,
      ability,
      refetchData,
      productOptions,
    } = useProductList();

    return {
      //Sidebar
      isAddNewDocumentTypeSidebarActive,

      fetchProduct,
      tableColumns,
      perPage,
      currentPage,
      totalProduct,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refProductTable,
      ability,
      refetchData,
      productOptions,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
