import { ref, watch, computed, onMounted } from "@vue/composition-api";
import store from "@/store";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ability from "@/libs/acl/ability";

export default function useProductList() {
  //product toast
  const toast = useToast();

  const refProductTable = ref(null);
  const productOptions = ref([]);

  const tableColumns = [
    { key: "id", label: "ID", sortable: true },
    { key: "product_name", label: "Product Name" },
    { key: "product_type_id", label: "Product Type" },
    { key: "product_price", label: "Product Price" },
    { key: "is_active", label: "Product Status" },
    { key: "action", label: "Action" },
  ];

  const perPage = ref(10);
  const totalProduct = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);

  const dataMeta = computed(() => {
    const localItemsCount = refProductTable.value
      ? refProductTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalProduct.value,
    };
  });

  const refetchData = () => {
    refProductTable.value.refresh();
  };

  watch([currentPage, perPage, searchQuery], () => {
    refetchData();
  });

  const fetchProduct = (ctx, callback) => {
    store
      .dispatch("product/fetchProduct", {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
      })
      .then((response) => {
        const { products, total } = response.data;
        callback(products);
        totalProduct.value = total;
      })
      .catch((error) => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching certification codes list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  const refetchProductOption = () => {
    store
      .dispatch("product/fetchProductOptions")
      .then((res) => (productOptions.value = res.data.product_types))
      .catch((err) => console.error("fail fetching product options ", err));
  };

  onMounted(() => {
    refetchProductOption();
  });

  return {
    fetchProduct,
    tableColumns,
    perPage,
    currentPage,
    totalProduct,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refProductTable,
    ability,
    refetchData,
    productOptions,
  };
}
