import axiosIns from "@/libs/axios";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchProduct(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/product/list", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateProduct(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("product", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    deleteProduct(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .delete(`product?id=${data.id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchProductOptions(ctx) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`/product_types/list`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
